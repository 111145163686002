var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "groupApproveList", staticStyle: { "margin-left": "0px" } },
    [
      _c(
        "Row",
        { staticStyle: { "margin-left": "0px" }, attrs: { gutter: 10 } },
        [
          _c(
            "Col",
            {
              staticStyle: { "padding-right": "0px", "padding-left": "0px" },
              style: _vm.leftSpan,
            },
            [
              _c(
                "Card",
                { staticClass: "d-card" },
                [
                  _c(
                    "Tabs",
                    {
                      attrs: { value: "name1" },
                      on: { "on-click": _vm.TabsClick },
                    },
                    [
                      _c("TabPane", {
                        attrs: { label: _vm.waitApprove, name: "name1" },
                      }),
                      _c("TabPane", {
                        attrs: { label: _vm.approved, name: "name2" },
                      }),
                    ],
                    1
                  ),
                  _c("Input", {
                    staticStyle: {
                      "margin-top": "10px",
                      "margin-bottom": "10px",
                    },
                    attrs: {
                      suffix: "ios-search",
                      placeholder: "请输入关键字",
                      clearable: "",
                    },
                    on: { "on-change": _vm.search },
                    model: {
                      value: _vm.searchForm.groupUnitName,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "groupUnitName", $$v)
                      },
                      expression: "searchForm.groupUnitName",
                    },
                  }),
                  _c(
                    "Row",
                    {
                      staticStyle: {
                        "justify-content": "center",
                        height: "calc(100% - 80px)",
                      },
                    },
                    [
                      _vm.groupOrderList.length > 0
                        ? _c(
                            "RadioGroup",
                            {
                              staticClass: "radio-group",
                              model: {
                                value: _vm.radioValue,
                                callback: function ($$v) {
                                  _vm.radioValue = $$v
                                },
                                expression: "radioValue",
                              },
                            },
                            _vm._l(_vm.groupOrderList, function (item, index) {
                              return _c(
                                "Row",
                                {
                                  key: item.id,
                                  staticClass: "row-border",
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.radioChooseClick(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "Col",
                                    {
                                      staticClass: "radio-group-radio",
                                      attrs: { span: 3 },
                                    },
                                    [
                                      _c("Radio", {
                                        staticClass: "label",
                                        attrs: { label: item.id },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Col",
                                    {
                                      staticClass: "radio-group-content",
                                      attrs: { span: 21 },
                                    },
                                    [
                                      _c(
                                        "Row",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "6px",
                                          },
                                        },
                                        [
                                          _c(
                                            "Col",
                                            {
                                              staticClass: "date",
                                              attrs: { span: 19 },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatTime(
                                                    item.signingTime
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "Col",
                                            {
                                              staticClass: "border-blue",
                                              attrs: { span: 5 },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.physicalType.indexOf(
                                                    "职业"
                                                  ) != -1
                                                    ? "职"
                                                    : "健"
                                                ) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Row",
                                        [
                                          _c(
                                            "Col",
                                            {
                                              staticClass: "company",
                                              attrs: {
                                                span: 19,
                                                title: item.groupUnitName,
                                              },
                                            },
                                            [_vm._v(_vm._s(item.groupUnitName))]
                                          ),
                                          _vm.searchForm.auditState != 99
                                            ? _c(
                                                "Col",
                                                {
                                                  staticClass: "bg-audit",
                                                  attrs: { span: 5 },
                                                },
                                                [_vm._v("待审")]
                                              )
                                            : _vm._e(),
                                          _vm.searchForm.auditState == 99
                                            ? _c(
                                                "Col",
                                                {
                                                  staticClass: "bg-green",
                                                  attrs: { span: 5 },
                                                },
                                                [_vm._v("已审")]
                                              )
                                            : _vm._e(),
                                          item.auditState == 5
                                            ? _c(
                                                "Col",
                                                {
                                                  staticClass: "bg-red",
                                                  attrs: { span: 5 },
                                                },
                                                [_vm._v("不通过")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _c("no-data"),
                      _vm.loading
                        ? _c(
                            "Spin",
                            { attrs: { fix: "" } },
                            [
                              _c("Icon", {
                                staticClass: "demo-spin-icon-load",
                                attrs: { type: "ios-loading", size: "18" },
                              }),
                              _c("div", [_vm._v("加载中...")]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.groupOrderList.length > 0
                        ? _c("Page", {
                            attrs: {
                              current: _vm.searchForm.pageNumber,
                              total: _vm.groupOrderTotal,
                              simple: "",
                            },
                            on: { "on-change": _vm.groupOrderChangePageNum },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "expand" },
            [
              _c("Icon", {
                staticClass: "icon",
                attrs: { type: _vm.expandIcon, size: "16" },
                on: { click: _vm.changeExpand },
              }),
            ],
            1
          ),
          _c(
            "Col",
            {
              staticStyle: { "padding-left": "0px", "padding-right": "0px" },
              style: _vm.span,
            },
            [
              _c(
                "Row",
                {
                  staticStyle: { "padding-left": "-15px" },
                  attrs: { gutter: 10 },
                },
                [
                  _c(
                    "Col",
                    { attrs: { span: 18 } },
                    [
                      _vm.groupOrderList.length > 0
                        ? _c(
                            "Card",
                            { staticClass: "d-card" },
                            [
                              _c(
                                "Row",
                                [
                                  _vm.searchForm.auditState != 99
                                    ? _c(
                                        "Button",
                                        {
                                          attrs: {
                                            type: "success",
                                            icon: "ios-paper",
                                            loading: _vm.adoptLoading,
                                          },
                                          on: { click: _vm.contractApproval },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.roleLevel) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.orderInfo.auditState == 4 ||
                                  _vm.orderInfo.auditState == 3 ||
                                  _vm.orderInfo.auditState == 2
                                    ? _c(
                                        "Button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            loading: _vm.loading,
                                          },
                                          on: { click: _vm.handelSubmit },
                                        },
                                        [_vm._v("评审报告预览")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "Button",
                                    {
                                      attrs: {
                                        type: "info",
                                        icon: "ios-cloud-upload-outline",
                                        loading: _vm.adoptLoading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showOrderPreview = true
                                        },
                                      },
                                    },
                                    [_vm._v(" 委托协议预览 ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "Row",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c(
                                    "Col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "Alert",
                                        { staticClass: "ivu-alert-left" },
                                        [_vm._v(" 体检项目 ")]
                                      ),
                                      _vm.groupData.length > 0
                                        ? _c(
                                            "el-tabs",
                                            {
                                              staticStyle: {
                                                "margin-top": "16px",
                                              },
                                              attrs: { type: "border-card" },
                                              on: {
                                                "tab-click": _vm.handleTabClick,
                                              },
                                            },
                                            _vm._l(
                                              _vm.groupData,
                                              function (item, index) {
                                                return _c(
                                                  "el-tab-pane",
                                                  {
                                                    key: index,
                                                    attrs: {
                                                      label:
                                                        item.name +
                                                        "(" +
                                                        (item.personCount
                                                          ? item.personCount
                                                          : 0) +
                                                        ")",
                                                    },
                                                  },
                                                  [
                                                    _c("Table", {
                                                      ref: "table",
                                                      refInFor: true,
                                                      attrs: {
                                                        loading:
                                                          _vm.tableLoading,
                                                        border: "",
                                                        "max-height": "450",
                                                        columns: _vm.columns,
                                                        sortable: "custom",
                                                        data: _vm.projectData,
                                                        "show-summary": "",
                                                        "summary-method":
                                                          _vm.handleSummary,
                                                      },
                                                    }),
                                                    _c(
                                                      "Form",
                                                      {
                                                        attrs: {
                                                          "label-width": 100,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "Row",
                                                          {
                                                            staticStyle: {
                                                              "margin-top":
                                                                "16px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "Col",
                                                              {
                                                                attrs: {
                                                                  span: "5",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "FormItem",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "必检项折扣：",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.discount
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "Col",
                                                              {
                                                                attrs: {
                                                                  span: "5",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "FormItem",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "选检项折扣：",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.addDiscount
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "Col",
                                                              {
                                                                attrs: {
                                                                  span: "5",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "FormItem",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "优惠价格：",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.discountPrice
                                                                            ? item.discountPrice
                                                                            : "-"
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "Col",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                },
                                                                attrs: {
                                                                  span: "5",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "FormItem",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "分组人数：",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.personCount
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "Col",
                                                              {
                                                                attrs: {
                                                                  span: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "FormItem",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "分组小计：",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " ￥" +
                                                                        _vm._s(
                                                                          _vm.subtotal
                                                                        ) +
                                                                        "元 "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.groupData.length > 0
                                        ? _c(
                                            "Alert",
                                            {
                                              staticClass: "ivu-alert-left",
                                              staticStyle: {
                                                "margin-top": "10px",
                                              },
                                            },
                                            [_vm._v(" 体检人员 ")]
                                          )
                                        : _vm._e(),
                                      _vm.groupData.length > 0
                                        ? _c("Table", {
                                            ref: "tablePerson",
                                            attrs: {
                                              loading: _vm.maleLoading,
                                              border: "",
                                              "max-height": "450",
                                              columns: _vm.maleColumns,
                                              sortable: "custom",
                                              data: _vm.groupPersonData,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.groupData.length > 0
                                        ? _c(
                                            "Row",
                                            {
                                              staticClass: "page",
                                              attrs: {
                                                type: "flex",
                                                justify: "end",
                                              },
                                            },
                                            [
                                              _c("Page", {
                                                attrs: {
                                                  current:
                                                    _vm.groupPersonSearchForm
                                                      .pageNumber,
                                                  total: _vm.groupPersonTotal,
                                                  "page-size":
                                                    _vm.groupPersonSearchForm
                                                      .pageSize,
                                                  transfer: "",
                                                  "page-size-opts": [
                                                    10, 20, 50,
                                                  ],
                                                  size: "small",
                                                  "show-total": "",
                                                  "show-elevator": "",
                                                  "show-sizer": "",
                                                },
                                                on: {
                                                  "on-change":
                                                    _vm.groupPersonChangePageNum,
                                                  "on-page-size-change":
                                                    _vm.groupPersonPageSize,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c("no-data", {
                                            attrs: {
                                              width: "150",
                                              height: "150",
                                            },
                                          }),
                                      _c(
                                        "Form",
                                        {
                                          staticStyle: { "margin-top": "20px" },
                                          attrs: { "label-width": 90 },
                                        },
                                        [
                                          _c(
                                            "Row",
                                            [
                                              _c(
                                                "Col",
                                                { attrs: { span: "5" } },
                                                [
                                                  _c(
                                                    "FormItem",
                                                    {
                                                      attrs: {
                                                        label: "订单合计：",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                            "font-size": "18px",
                                                            "white-space":
                                                              "nowrap",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "￥" +
                                                              _vm._s(
                                                                this
                                                                  .groupOrderForm
                                                                  .orderTotal
                                                              ) +
                                                              "元"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "Card",
                            {
                              staticStyle: {
                                "margin-left": "0px",
                                height: "100%",
                              },
                            },
                            [
                              _c("no-data", {
                                attrs: { height: "150", width: "150" },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c(
                    "Col",
                    {
                      staticStyle: { "padding-left": "-15px" },
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "Card",
                        { staticClass: "d-card" },
                        [
                          _c(
                            "Alert",
                            {
                              staticClass: "ivu-alert-left",
                              staticStyle: { "min-width": "300px" },
                            },
                            [_vm._v(" 基本信息 ")]
                          ),
                          _vm.orderInfo &&
                          _vm.orderInfo.id &&
                          _vm.orderInfo.id.trim().length > 0
                            ? _c(
                                "Card",
                                {
                                  staticStyle: {
                                    height: "calc(100% - 50px)",
                                    "min-width": "300px",
                                  },
                                },
                                [
                                  _c(
                                    "Form",
                                    {
                                      ref: "tGroupOrderForm",
                                      staticClass: "form",
                                      attrs: {
                                        model: _vm.orderInfo,
                                        "label-width": 87,
                                      },
                                    },
                                    [
                                      _c(
                                        "Row",
                                        [
                                          _c(
                                            "Col",
                                            { attrs: { span: "24" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "合同名称:" },
                                                },
                                                [_vm._v(" 职业体检评审 ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "24" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "合同编号:" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.orderInfo.orderCode
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "24" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "委托单位:" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.orderInfo
                                                          .groupUnitName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "24" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "委托内容:" },
                                                },
                                                [_vm._v(" 职业体检 ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "24" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "编制部门:" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.hospitalNameData &&
                                                          _vm.hospitalNameData
                                                            .name
                                                          ? _vm.hospitalNameData
                                                              .name
                                                          : ""
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "24" } },
                                            [
                                              _c(
                                                "FormItem",
                                                { attrs: { label: "编制人:" } },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.hospitalNameData &&
                                                          _vm.hospitalNameData
                                                            .legalPerson
                                                          ? _vm.hospitalNameData
                                                              .legalPerson
                                                          : ""
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "24" } },
                                            [
                                              _c(
                                                "FormItem",
                                                { attrs: { label: "审核人:" } },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.auditUserName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "24" } },
                                            [
                                              _vm.searchForm &&
                                              _vm.searchForm.auditState &&
                                              _vm.searchForm.auditState == 99
                                                ? _c(
                                                    "FormItem",
                                                    {
                                                      staticStyle: {
                                                        color: "#42C3AF",
                                                        "font-weight": "bolder",
                                                      },
                                                      attrs: {
                                                        label: "审核状态:",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.searchForm &&
                                                              _vm.searchForm
                                                                .auditState &&
                                                              _vm.searchForm
                                                                .auditState ==
                                                                99
                                                              ? "已审核"
                                                              : "待审核"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "FormItem",
                                                    {
                                                      staticStyle: {
                                                        color: "red",
                                                        "font-weight": "bolder",
                                                      },
                                                      attrs: {
                                                        label: "审核状态:",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.searchForm &&
                                                              _vm.searchForm
                                                                .auditState &&
                                                              _vm.searchForm
                                                                .auditState ==
                                                                99
                                                              ? "已审核"
                                                              : "待审核"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "24" } },
                                            [
                                              _c(
                                                "FormItem",
                                                { attrs: { label: "联系人:" } },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.tGroupUnit &&
                                                          _vm.tGroupUnit
                                                            .linkMan2
                                                          ? _vm.tGroupUnit
                                                              .linkMan2
                                                          : ""
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "24" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "联系电话:" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.tGroupUnit &&
                                                          _vm.tGroupUnit
                                                            .linkPhone2
                                                          ? _vm.tGroupUnit
                                                              .linkPhone2
                                                          : ""
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "24" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "审核日期:" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.auditTime) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "销售负责人:",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.groupOrderForm
                                                          .salesDirector
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "签订时间:" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.groupOrderForm
                                                          .signingTime
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "交付时间:" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.groupOrderForm
                                                          .deliveryTime
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "Card",
                                [
                                  _c("no-data", {
                                    staticStyle: {
                                      height: "calc(100vh - 83px)",
                                      overflow: "hidden",
                                      display: "flex",
                                      "flex-direction": "row",
                                      "justify-content": "center",
                                    },
                                    attrs: { width: "150" },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Modal",
            {
              attrs: {
                title: _vm.modalTitle,
                "mask-closable": false,
                closable: false,
              },
              model: {
                value: _vm.showContent,
                callback: function ($$v) {
                  _vm.showContent = $$v
                },
                expression: "showContent",
              },
            },
            [
              _c("Input", {
                attrs: {
                  type: "textarea",
                  rows: 4,
                  placeholder: "请输入审核意见",
                },
                model: {
                  value: _vm.auditContent,
                  callback: function ($$v) {
                    _vm.auditContent = $$v
                  },
                  expression: "auditContent",
                },
              }),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "Button",
                    { attrs: { type: "text" }, on: { click: _vm.modalCancel } },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "Button",
                    {
                      attrs: { type: "primary", loading: _vm.confirmLoading },
                      on: { click: _vm.confirmSubmit },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("contract-preview", {
            attrs: { url: _vm.groupOrderForm.orderPath },
            model: {
              value: _vm.showOrderContract,
              callback: function ($$v) {
                _vm.showOrderContract = $$v
              },
              expression: "showOrderContract",
            },
          }),
        ],
        1
      ),
      _c("previewTemplate", {
        attrs: {
          groupOrderInfo: JSON.parse(JSON.stringify(_vm.groupOrderForm)),
        },
        model: {
          value: _vm.showOrderPreview,
          callback: function ($$v) {
            _vm.showOrderPreview = $$v
          },
          expression: "showOrderPreview",
        },
      }),
      _c("approveShow", {
        attrs: {
          orderId: _vm.orderInfo.id,
          titleData: _vm.titleData,
          url: _vm.groupOrderForm.orderPath,
        },
        on: {
          submitApproveListShowPage: _vm.submitApproveListShowPage,
          closeLoading: _vm.closeLoading,
        },
        model: {
          value: _vm.approvalShow,
          callback: function ($$v) {
            _vm.approvalShow = $$v
          },
          expression: "approvalShow",
        },
      }),
      _c("review-content", {
        attrs: {
          orderId: _vm.orderInfo.id,
          orderInfo: _vm.orderInfo,
          flowData: _vm.flowData,
        },
        on: {
          closeLoading: _vm.closeLoading,
          submitApproveShowPage: _vm.submitApproveListShowPage,
        },
        model: {
          value: _vm.reviewContentShow,
          callback: function ($$v) {
            _vm.reviewContentShow = $$v
          },
          expression: "reviewContentShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }